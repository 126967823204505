<template>
  <b-modal
    id="exchange-rate-edit-modal"
    ref="exchange-rate-edit-modal"
    title="Edit"
    centered
    hide-footer
    no-close-on-backdrop
    size="lg"
    @shown="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>
        <b-row>
          <!-- !! Code -->
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              name="Currency Code"
              rules="required"
            >
              <HeroVueSelect
                id="status"
                v-model="exchangeRates.currency_code"
                label="Currency Code"
                :required="true"
                :clearable="false"
                :options="currencyCodeOptions"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
                :disabled="true"
              />
            </validation-provider>
          </b-col>
          <!-- !! Name -->
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              name="Exchange Rate"
              rules="required|min_value:1"
            >
              <HeroInputNumber
                id="exchange_rate"
                v-model="exchangeRates.exchange_rate"
                label="Exchange Rate"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- !! Table Header -->
    <div class="mx-1">
      <b-row>
        <b-col class="mt-1" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
          <HeroInputText
            id="filter"
            v-model="tableConfig.filter"
            placeholder="Search"
            @input="doTableFilter(500)"
          />
        </b-col>
        <b-col class="mt-1" order="1" cols="6" sm="4" md="3" lg="2">
          <HeroTablePerPage
            v-model="tableConfig.perPage"
            :options="tableConfig.perPageOptions"
            @input="doTableFilter(100)"
          />
        </b-col>
      </b-row>
    </div>

    <!-- !! Table -->
    <b-row class="my-1">
      <b-col cols="12">
        <b-table
          show-empty
          striped
          sticky-header="100%"
          :responsive="true"
          :items="items"
          :fields="tableConfig.fields"
          :per-page="0"
          :no-sort-reset="true"
          :no-local-sorting="true"
          @sort-changed="doTableSort"
        >
          <template #cell(deleted_at)="data">
            {{ formatUsaDate(data.item.deleted_at) }}
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- !! Table Footer -->
    <div class="mx-1 mb-1">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
          <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import DataFormatService from '@/services/DataFormatService'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputText,
    HeroInputNumber,
    HeroVueSelect,
    HeroButtonAction,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    editId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: true,

      // Validation
      required,

      routeId: '',
      currencyCodeOptions: [],
      exchangeRates: {
        exchange_rate: 0,
        currency_code: '',
      },

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        timeInterval: moment(),
        fields: [
          {
            label: 'Currency Code',
            key: 'currency_code',
            sortable: false,
          },
          {
            label: 'Exchange Rate',
            key: 'exchange_rate',
            sortable: false,
          },
          {
            label: 'Deleted By',
            key: 'deleted_by',
            sortable: false,
          },
          {
            label: 'Deleted At',
            key: 'deleted_at',
            sortable: false,
          },
        ],
      },
    }
  },
  created() {
    this.formatUsaDate = DataFormatService.formatUsaDate
  },
  methods: {
    async doCloseModal() {
      await this.$refs['exchange-rate-edit-modal'].hide()
    },

    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadTableData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadTableData()
    },

    async doLoadData() {
      this.showOverlay = true
      this.currencyCodeOptions = []
      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const response = await axios.get(`exchange-rates/${this.editId}`, axiosConfig)
        const exchangeRates = response.data.data

        this.currencyCodeOptions = [{
          text: exchangeRates.currency_code,
          value: exchangeRates.currency_code,
        }]

        this.exchangeRates.exchange_rate = exchangeRates.exchange_rate || 0
        this.exchangeRates.currency_code = exchangeRates.currency_code || ''
      } catch (error) {
        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }

        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
        this.doLoadTableData()
      }
    },

    async doLoadTableData() {
      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const {
          perPage, currentPage, filter,
        } = this.tableConfig

        const url = `exchange-rates/history?perPage=${perPage}&page=${currentPage}&q=${filter}&currency_code=${this.exchangeRates.currency_code}`
        const response = await axios.get(url, axiosConfig)

        this.tableConfig.currentPage = Number(response.data.data.current_page)
        this.tableConfig.perPage = Number(response.data.data.per_page)
        this.tableConfig.totalRows = Number(response.data.data.total)
        this.items = response.data.data.data
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        this.tableConfig.totalRows = 0
      }
    },

    async validationForm() {
      let alertResult

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        this.showOverlay = true

        const body = this.exchangeRates

        try {
          const response = await axios.patch(`exchange-rates/${this.editId}`, body, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.$emit('success')
            this.$refs['exchange-rate-edit-modal'].hide()
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },
  },
}
</script>
