<template>
  <b-modal
    id="exchange-rate-create-modal"
    ref="exchange-rate-create-modal"
    title="Create"
    centered
    hide-footer
    no-close-on-backdrop
    @shown="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>
        <b-row>
          <!-- !! Code -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Currency Code"
              rules="required"
            >
              <HeroVueSelect
                id="status"
                v-model="exchangeRates.currency_code"
                label="Currency Code"
                :required="true"
                :clearable="false"
                :options="currencyCodeOptions"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <!-- !! Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Exchange Rate"
              rules="required|min_value:1"
            >
              <HeroInputNumber
                id="exchange_rate"
                v-model="exchangeRates.exchange_rate"
                label="Exchange Rate"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputNumber,
    HeroVueSelect,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,

      currencyCodeOptions: [],
      original: {
        exchange_rate: 0,
        currency_code: '',
      },
      exchangeRates: {
        exchange_rate: 0,
        currency_code: '',
      },
    }
  },
  methods: {
    doCloseModal() {
      this.country = { ...this.original }
      this.$refs['exchange-rate-create-modal'].hide()
    },

    async doLoadData() {
      this.showOverlay = true
      this.currencyCodeOptions = []
      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)
      try {
        const response = await axios.get('dropdown-options/currency-code-options', axiosConfig)
        if (response.data.data) {
          this.currencyCodeOptions = response.data.data.map(x => ({
            text: x,
            value: x,
          }))
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async validationForm() {
      let alertResult

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        this.showOverlay = true

        const body = this.exchangeRates

        try {
          const response = await axios.post('exchange-rates', body, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.$emit('success')
            this.exchangeRates = { ...this.original }
            this.$refs['exchange-rate-create-modal'].hide()
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },
  },
}
</script>

//make new list object using map in javascript?
